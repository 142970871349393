.center-summary {
    padding-top: calc(max(env(safe-area-inset-top), 20px) + 20px);
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    63% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.journal-fab {
    bottom: 30px;
    right: calc(30px + var(--ion-safe-area-right, 0px));
    opacity: 1;
    animation: fadeIn 0.8s;
}

@media only screen and (max-width: 800px) {
    .journal-fab {
        bottom: 20px;
        right: calc(20px + var(--ion-safe-area-right, 0px));
    }
}

.mood-log-list {
    overflow: auto;
    margin: 0 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    grid-area: logs;
}

.mood-log-list::-webkit-scrollbar {
    display: none;
}

.reversed-list-spacer {
    width: 100%;
    max-height: 80%;
    min-height: 50px;
}

.log-list-expand {
    font-size: 20px;
    place-self: stretch center;
    transition: height 0.3s;
    grid-area: logs;
    z-index: 3;
    width: 100%;
    text-align: center;
    background-color: var(--ion-background-color);
}

.log-list-expand.filter-area, .grid-heading.filter-area {
    display: flex;
    flex-direction: column;
}

.log-list-expand.filter-area {
    height: 116px;
}

.grid-heading.filter-area {
    height: calc(128px + max(env(safe-area-inset-top), 16px))
}

.filter-selects {
    display: flex;
    justify-content: center;
    padding-top: 8px;
}

.week-summary-grid {
    display: grid;
    grid-template-rows: auto min-content auto;
    grid-template-columns: auto;
    grid-template-areas:
        "heading"
        "graph"
        "logs";
    height: 100vh;
    overflow: hidden;
    align-content: start;
    position: relative;
    width: 90%;
    margin: 0 auto;
    max-width: 600px;
}

.month-summary-grid {
    display: grid;
    grid-template-rows: auto 35px auto;
    grid-template-columns: 65% 35%;
    grid-template-areas: 
        "heading heading"
        "dates logs"
        "calendar logs";
    height: 100vh;
    overflow: hidden;
    align-content: start;
    position: relative;
}

.container.menu-content {
    transform: none;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;
}

.helper-text {
    font-weight: normal;
}

.marker {
    height: 6.5px;
    width: 6.5px;
    position: absolute;
    border-radius: 2px;
    background-color: black;
}

@media screen and (min-width: 600px) {
    .marker {
        height: 7.5px;
        width: 7.5px;
    }
}

.menu {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.menu > .move-rest-down {
    margin-top: auto;
}

.head2 {
    font-size: 20px;
}

.invisible-input.searchbar {
    width: 85%;
    background-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07);
    border-radius: 5px; 
    height: 35px;
    text-align: center;
    font-size: 18px;
    padding-top: 4px;
    font-family: "Lato", sans-serif;
    grid-area: search;
    margin: 0 auto;
}

.invisible-input.searchbar.week {
    margin: calc(max(env(safe-area-inset-top), 20px) + 7px) auto 0 auto;
    width: 75%;
}

.grid-heading {
    grid-area: heading;
}

.filter-number {
    grid-area: number;
}

.filter-average {
    grid-area: average;
}

.dropdown-content {
    z-index: 3 !important;
}

.image-btn {
    padding-top: 6px;
    padding-left: 8px;
    font-size: 1.1em;
}

@media screen and (max-width: 900px) {
    .image-btn {
        font-size: 1.3em;
    }
}

p.first-journal {
    margin-top: 12px;
}