.prompt-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.prompt-container {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    flex-direction: column;
}

.prompt-prompt {
    z-index: 1001;
    background-color: var(--ion-background-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    padding: 10px;
    padding-top: 20px;
    transition: height 0.5s;
}

.finish-button.secondary {
    margin-top: 5px;
    margin-bottom: 3px;
    background-color: transparent;
    color: var(--background-color-inverted);
}

.finish-button.secondary:active {
    background-color: transparent;
}

.wir-skip-buttons {
    width: 75%;
    display: flex;
    margin: 0 auto;
}