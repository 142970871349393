div[role="dialog"] {
    z-index: 1002;
}

.rss-footer {
    display: none;
}

.rss-header {
    padding: 10px 24px;
}

.rss-header > svg {
    top: 10px;
}

.rss-content {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 70px;
    padding-bottom: 32px;
}

.spotify-track {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 64px auto;
    grid-template-areas: "album title" "album artists";
    column-gap: 13px;
    height: 64px;
    margin-bottom: 12px;

    cursor: pointer;
}

.spotify-track:hover {
    background-color: var(--card-background);
    border-radius: 4px;
}

.spotify-title {
    grid-area: title;
    padding-top: 8px;
    font-size: 1.1em;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spotify-artists {
    grid-area: artists;
    color: grey;
    margin-top: -4px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.spotify-album {
    grid-area: album;
    width: 64px;
    height: 64px;
    border-radius: 4px;
}

.spotify-search {
    margin-bottom: 16px;
}

.spotify-explicit {
    background-color: grey;
    color: white;
    padding: 0 4px 1px;
    margin-right: 4px;
    border-radius: 2px;
}

.spotify-modal-close {
    float: right;
    margin-top: 3px;
}

.spotify-start-btn {
    margin-top: 7px;
    cursor: pointer;
}

.rss-backdrop {
    background-color: var(--ion-background-color);
}

.spotify-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 16px auto 4px;
}

.spotify-link > img {
    width: 24px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spotify-search-loading ion-icon.searchbar-search-icon {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}