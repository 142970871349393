.notification-editor {
    width: 90%;
    background-color: var(--card-background);
    clip-path: inset(0% 0% 0% 0% round 10px);
    margin-bottom: 12px;
    text-align: center;
    max-width: 300px;
}

.weekday-selector {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    line-height: 24px;
    font-size: 15px;
}

.italics {
    font-style: italic;
}

.weekday-selectors {
    display: flex;
    justify-content: space-evenly;
    margin: 12px 6px;
}

.notification-edit-buttons {
    display: flex;
    justify-content: space-evenly;
    font-size: 24px;
    margin: 6px;
}

.datetime-round {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}