.center-main {
    font-weight: bold;
    padding-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.drop {
    padding-top: 13vh;
}

.center-journal {
    padding-top: calc(max(env(safe-area-inset-top), 20px) + 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.center-journal.center-screener {
    padding-top: calc(max(env(safe-area-inset-top), 12px) - 5px);
}

.center-notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fakeTextArea {
    width: 80%;
    height: 300px;
    background-color: rgb(230, 228, 228);
    border-radius: 20px;
}

.fakeTextAreaContent {
    padding-left: 20px;
    color: rgb(99, 99, 99);
}

.tx {
    font-weight: normal;
    width: 90%;
    padding: 8px;
    padding-left: 12px;
    border: none;
    resize: none;
    overflow: auto;
    outline: none;
    line-height: 21px;
    overflow-y: clip;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.tx::-webkit-resizer {
    display: none;
}

.tx::-webkit-scrollbar {
    display: none;
}

.tx:focus {
    border: none;
    box-shadow: none;
}

.tx.tx-display {
    margin: 0 auto;
    padding-right: 6px;
    width: 98%;
}

/* Auto-Resize Textbox */

*,
*::before,
*::after {
    box-sizing: border-box;
}

.input-sizer {
    display: inline-grid;
    vertical-align: top;
    align-items: center;
    position: relative;
    width: 90%;
    line-height: 21px;
}

.input-sizer.sizing-only {
    display: block;
}

@media only screen and (min-width: 800px) {
    .input-sizer {
        width: 80%;
    }
}

.input-sizer.stacked {
    align-items: stretch;
}

.input-sizer.stacked::after,
.input-sizer.stacked input,
.input-sizer.stacked textarea,
.input-sizer.stacked div {
    grid-area: 2/1;
}

.input-sizer::after,
.input-sizer input,
.input-sizer textarea,
.input-sizer div {
    width: auto;
    min-width: 1em;
    grid-area: 1/2;
    font: inherit;
    padding: 0.25em;
    margin: 0;
    resize: none;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
}

.input-sizer::after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
}

/* Auto-Resize Textbox end */

.fake-button {
    width: 200px;
    height: 50px;
    background-color: #4b7ef7;
    text-align: center;
    line-height: 50px;
    border-radius: 10px;
    margin-top: 15px;
    color: white;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.bottom-bar {
    z-index: 1000;
    position: absolute;
    left: 17.5%;
    width: 65%;
    background-color: var(--card-background);
    border-radius: 10px 10px 0 0;
    height: 148px;
    bottom: 0px;
}

@media only screen and (max-width: 800px) {
    .bottom-bar {
        left: 2.5%;
        width: 95%;
    }
}

.finish-button {
    width: 97%;
    background-color: #4b7ef7;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: white;
    margin: 0 auto;
    border-radius: 10px;

    cursor: pointer;
}

.finish-button:active {
    background-color: #2e6cfc;
}

.outline-button {
    border: #4b7ef7 2px solid;
    border-radius: 4px;
    margin-left: 8px;
    padding: 2px;
    cursor: pointer;
}

.outline-button:hover {
    border: #2e6cfc 2px solid;
}

.loader {
    position: relative;
    top: 7px;
}

.line1 {
    margin: 16px 0 5px;
}

.line2 {
    margin: 0 25px 15px;
    font-weight: normal;
}

input[type=file]#files {
    display: none;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

ion-segment-button > ion-label {
    text-overflow: unset !important;
    max-width: unset !important;
}

.journal-additions-icon {
    font-size: 25px;
    transform: translateY(6px);
}

.secondary-icon {
    font-size: 20px;
    transform: translateY(5px);
    cursor: pointer;
}

.jc-faked-input {
    word-wrap: break-word;
    white-space: pre-wrap;
}

.jc-faked-container {
    position: absolute;
    visibility: hidden;
    top: 0px;
}